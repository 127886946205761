import React, { useState, useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import {
  Alert,
  Box,
  CssBaseline,
  Typography,
  Button,
  LinearProgress,
  styled,
  TextField,
} from "@mui/material";

function App() {
  const s = new URLSearchParams(document.location.search);
  const apiUrl = s.get("api") ?? "https://api.lakimi.feimsoft.com";
  const agentId = s.get("agent");

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [agentInfo, setAgentInfo] = useState(null);
  const [documents, setDocuments] = useState([]);
  const inputRef = React.useRef(null);

  const handleChangeFile = async (e) => {
    const files = e.target.files;
    const fileList = [];

    for (let file of files) {
      const reader = new FileReader();

      reader.onloadend = function () {
        const dataUrl = reader.result;

        const fileType = file.type.startsWith("image") ? "image" : (file.type === "application/pdf" ? "pdf" : "other");

        fileList.push({
          name: file.name,
          type: fileType,
          file_url: dataUrl,
        });

        setDocuments([...fileList]);
      };

      reader.readAsDataURL(file);
    }
  };


  const handleExecute = async () => {
    setLoading(true);

    let params = null;

    try {
      params = inputRef.current.value ? JSON.parse(inputRef.current.value) : {}
    } catch (e) {
      params = inputRef.current.value ?? '';
    }

    const response = await fetch(
      `${apiUrl}/agents/${s.get("agent")}/execute`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          documents: documents,
          params,
        }),
      }
    );

    const isJson = response.headers.get("content-type")?.includes("json");
    if (isJson) {
      const obj = await response.json();
      setResponse(JSON.stringify(obj, null, 2));
    } else {
      const text = await response.text();
      setResponse(text);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!agentId) {
      setError("No se ha especificado un agente");
    } else {
      setError("");
      fetch(`${apiUrl}/agents/${agentId}/info`).then(async (response) => {
        setAgentInfo(await response.json())
      });
    }
  }, [agentId, apiUrl])

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          m: 2,
        }}
      >
        <Box
          sx={{
            maxWidth: 600,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h5">
            {agentInfo ? agentInfo.name : "Cargando..."}
          </Typography>
          {
            error && <Alert variant="standard" color="error">{error}</Alert>
          }
          {
            agentInfo && (
              <Alert variant="standard" color="info" sx={{ 'whiteSpace': 'pre-wrap' }}>
                {agentInfo.description}
              </Alert>
            )
          }

          <Box>
            <TextField inputRef={inputRef} minRows={2} multiline fullWidth label="Parámetros" placeholder={`{
  "param_1": "value",
  "param_2": "value",
  ...
}`} />
          </Box>

          <Box>
            <Typography variant="h6">Documentos</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {documents.map((doc, i) => (
                <Box key={i} sx={{ display: 'flex', gap: 1 }}>
                  <Typography>{doc.name}</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setDocuments(documents.filter((_, j) => i !== j));
                    }}
                  >
                    Eliminar
                  </Button>
                </Box>
              ))}
              {documents.length === 0 && <Typography>No hay documentos adjuntos</Typography>}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" size="small" onClick={handleExecute}>Ejecutar agente</Button>

            <Button
              onClick={(e) => {
                e.target.querySelector("input")?.click();
              }}
              variant="contained"
              size="small"
              color="secondary"
            >
              <input type="file" multiple style={{ display: 'none' }} onChange={handleChangeFile} />
              Adjuntar documento
            </Button>

          </Box>
          {loading && <LinearProgress />}
          {!loading && response && response.length > 0 && (
            <Box>
              <Typography gutterBottom>Resultado:</Typography>
              <Response>{response}</Response>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const Response = styled("pre")`
      white-space: pre-wrap;
      background-color: #000000;
      padding: 1rem;
      box-shadow: 5px 5px 5px #8b8b8b;
      color: #edeeef;
      `;

export default App;
